import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Bilderstoeckchen: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Bilderstoeckchen,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Bilderstöckchen"
          d="M471.3,473.4L495,502l-2,56.6l-5.2-0.3l0.2-0.3l-10-12l-6-14l-24-2c-8.8-18.6-27.1-57.2-36-76l25-22 L471.3,473.4z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 438.4979 492.8186)"
        >
          <tspan x="0" y="0">
            Bilder-
          </tspan>
          <tspan x="0" y="14.4">
            stöckchen
          </tspan>
        </text>
      </g>
    </>
  );
});
